import Vue from 'vue';
import VueRouter from 'vue-router';
import config from '@/config';
import local from '@/utils/local';
const title = config.title;

Vue.use(VueRouter);

const routes = [
	// 默认页面
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/upload',
		name: 'upload',
		component: (resolve) => require(['@/views/upload'], resolve)
	},
	// 登录
	{
		path: '/LogIn',
		name: 'LogIn',
		component: (resolve) => require(['@/views/LogIn/index'], resolve)
	},
	// 一级路由
	{
		path: '/layout',
		component: () => import('../views/layout/index.vue'),
		// 二级路由
		children: [
			{
				path: '/',
				redirect: '/home'
			},
			{
				path: '/home',
				name: 'home',
				component: (resolve) => require(['@/views/HomePage'], resolve),
				meta: {
					title: title + '-首页'
				}
			},
			{
				path: '/software',
				name: 'software',
				component: (resolve) => require(['@/views/Software'], resolve),
				meta: {
					title: title + '-软件产品'
				},
				children: [
					{
						path: '/software',
						redirect: '/software/smartTown'
					},
					{
						path: '/software/smartTown',
						name: 'smartTown',
						component: (resolve) => require(['@/views/Software_smartTown'], resolve),
						meta: {
							title: '软件产品丨Tessai资管后台'
						}
					},
					{
						path: '/software/bigData',
						name: 'bigData',
						component: (resolve) => require(['@/views/Software_bigData'], resolve),
						meta: {
							title: '软件产品丨Tessai资管客户端'
						}
					}
				]
			},
			// 方案服务
			{
				path: '/service',
				name: 'service',
				component: (resolve) => require(['@/views/SchemeDesign/Service'], resolve)
				// meta: {
				//   title: title + "-证书及荣誉"
				// }
			},
			// 物资供应
			{
				path: '/companyintroduction',
				name: 'companyintroduction',
				component: (resolve) => require(['@/views/MaterialSupply/CompanyIntroduction'], resolve)
				// meta: {
				// 	title: title + '-主营业务'
				// }
			},
			// 寻商机
			{
				path: '/business',
				name: 'business',
				component: (resolve) => require(['@/views/ExternalItem/business'], resolve)
			},
			// 招投标
			{
				path: '/bidding',
				name: 'bidding',
				component: (resolve) => require(['@/views/bidding/index'], resolve)
			},
			// 招投标详情
			{
				path: '/bidding/detail',
				name: 'biddingDetail',
				component: (resolve) => require(['@/views/bidding/detail'], resolve)
			},
			// 寻商机详情
			{
				path: '/business/BusinessDetails',
				name: 'BusinessDetails',
				component: (resolve) => require(['@/views/ExternalItem/BusinessDetails'], resolve)
			},
			// 需求集市
			{
				path: '/market',
				name: 'market',
				component: (resolve) => require(['@/views/DemandMarket/market'], resolve)
			},
			// 需求详情
			{
				path: '/DemandDetails/details',
				name: 'details',
				component: (resolve) => require(['@/views/DemandDetails/details'], resolve)
			},
			// 电力服务
			{
				path: '/newsinformation',
				name: 'newsinformation',
				component: (resolve) => require(['@/views/NewsInformation'], resolve)
				// meta: {
				// 	title: title + '-新闻动态'
				// }
			},
			// {
			// 	path: '/newsinformationdetail',
			// 	name: 'newsinformationdetail',
			// 	component: (resolve) => require(['@/views/NewsInformationDetail'], resolve)
			// 	// meta: {
			// 	// 	title: title + '-新闻详情'
			// 	// }
			// },
			// 个人中心
			{
				path: '/personCenter',
				name: 'personCenter',
				component: (resolve) => require(['@/views/personCenter'], resolve),
				meta: {
					title: title + '-个人中心'
				},
				// 三级个人中心
				children: [
					{
						path: '/personCenter',
						redirect: '/personCenter/index'
					},
					{
						path: '/personCenter/index',
						name: 'person',
						component: (resolve) => require(['@/views/personCenter/person'], resolve),
						meta: {
							title: title
						}
					},
					// 修改信息
					{
						path: '/personCenter/editInfo',
						name: 'editInfo',
						component: (resolve) => require(['@/views/personCenter/editInfo'], resolve),
						meta: {
							title: title + '-修改信息'
						}
					},
					{
						path: '/personCenter/myPublic',
						name: 'myPublic',
						component: (resolve) => require(['@/views/personCenter/myPublic'], resolve),
						meta: {
							title: title + '-我的发布'
						}
					},
					{
						path: '/personCenter/signUp',
						name: 'signUp',
						component: (resolve) => require(['@/views/personCenter/signUp'], resolve),
						meta: {
							title: title + '-我的发布-报名人员'
						}
					},
					{
						path: '/personCenter/price',
						name: 'price',
						component: (resolve) => require(['@/views/personCenter/price'], resolve),
						meta: {
							title: title + '-我的集采-报价'
						}
					},
					{
						path: '/personCenter/showPrice',
						name: 'showPrice',
						component: (resolve) => require(['@/views/personCenter/showPrice'], resolve),
						meta: {
							title: title + '-我的集采-查看报价'
						}
					},
					{
						path: '/personCenter/updatePrice',
						name: 'UpdatePrice',
						component: (resolve) => require(['@/views/personCenter/updatePrice'], resolve),
						meta: {
							title: title + '-我的集采-增加报价'
						}
					},
					// 方案服务
					{
						path: '/DemandDetails/SolutionService',
						name: 'SolutionService',
						component: (resolve) => require(['@/views/DemandDetails/SolutionService'], resolve),
						meta: {
							title: title + '-方案服务'
						}
					},
					{
						path: '/personCenter/myApply',
						name: 'myApply',
						component: (resolve) => require(['@/views/personCenter/myApply'], resolve),
						meta: {
							title: title + '-我的报名'
						}
					},
					{
						path: '/personCenter/publicDemand',
						name: 'publicDemand',
						component: (resolve) => require(['@/views/personCenter/publicDemand'], resolve),
						meta: {
							title: title + '-发布需求'
						}
					},
					//我的集采
					{
						path: '/personCenter/procurement',
						name: 'procurement',
						component: (resolve) => require(['@/views/personCenter/procurement'], resolve),
						meta: {
							title: title + '-我的集采'
						}
					},
					// 品牌计划
					{
						path: '/personCenter/brand',
						name: 'brand',
						component: (resolve) => require(['@/views/personCenter/brand'], resolve),
						meta: {
							title: title + '-品牌计划'
						}
					},
					// 我的收藏
					{
						path: '/personCenter/myCollection',
						name: 'myCollection',
						component: (resolve) => require(['@/views/personCenter/myCollection'], resolve),
						meta: {
							title: title + '-我的收藏'
						}
					},
					// 综合认证
					{
						path: '/personCenter/synthesis',
						name: 'synthesis',
						component: (resolve) => require(['@/views/personCenter/synthesis'], resolve),
						meta: {
							title: title + '-综合认证'
						}
					},
					// 需求详情（三级页面）
					{
						path: '/personCenter/details',
						name: 'detailsS',
						component: (resolve) => require(['@/views/DemandDetails/detailsCopy'], resolve),
						meta: {
							title: title + '-需求详情'
						}
					}
				]
			},
			// 供应商
			{
				path: '/supplier',
				name: 'supplier',
				component: (resolve) => require(['@/views/supplier/supplier'], resolve)
			},
			// 供应商详情
			{
				path: '/supplierInfo',
				name: 'supplierInfo',
				component: (resolve) => require(['@/views/supplier/supplierInfo'], resolve)
			},
			// 供应商发布/入围的需求
			{
				path: '/supplierInfo/relevance',
				name: 'relevance',
				component: (resolve) => require(['@/views/supplier/relevance'], resolve)
			},
			// 供应商的资质
			{
				path: '/supplierInfo/qualified',
				name: 'qualified',
				component: (resolve) => require(['@/views/supplier/qualified'], resolve)
			},
			{
				path: '/contactus',
				name: 'contactus',
				component: (resolve) => require(['@/views/ContactUs'], resolve),
				meta: {
					title: title + '-联系我们'
				}
			},
			{
				path: '/servicedetail',
				name: 'servicedetail',
				component: (resolve) => require(['@/views/ServiceDetail'], resolve),
				meta: {
					title: title + '-证书及荣誉'
				}
			},
			// 分享用的页面
			{
				path: '/share/nationwideProject',
				name: 'nationwideProject',
				component: () => import('@/views/share/nationwideProject.vue')
			}
		]
	},
	// // 登录注册
	// {
	//   path: "/login",
	//   component: () => import("../views/login/Login.vue"),
	// },

	// 404页面
	{
		path: '/error404',
		component: () => import('../views/error404/Error404.vue')
	},
	// 乱输进入404页面
	{
		path: '*',
		redirect: '/error404'
	}
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition && to.meta.keepAlive) {
		return savedPosition;
	}
	return { x: 0, y: 0 };
};

// hash模式
const router = new VueRouter({
	base: '/',
	routes,
	scrollBehavior
});
const filerLogUris = ['person', 'brand', 'synthesis', 'myPublic', 'myApply', 'myCollection', 'publicDemand'];
router.beforeEach((to, from, next) => {
	// console.log('前置路由守卫', to, from)
	if (to.name == 'LogIn') {
		next();
	} else {
		if (filerLogUris.indexOf(to.name) >= 0) {
			const flag = local.getToken('token');
			if (flag) {
				// 登录了 就放行
				next();
			} else {
				// 没有登录
				next('/LogIn');
			}
		} else {
			next();
		}
	}
});
// history模式
// const router = new VueRouter({
//   mode: 'history',
//   scrollBehavior: () => ({ y: 0 }),
//   routes
// })

export default router;
