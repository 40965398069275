import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// 引入elementUI
import ElementUI from 'element-ui'; // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css'; // element-ui的css样式要单独引入
Vue.use(ElementUI); // 这种方式引入了ElementUI中所有的组件

// 引入公共样式和重置样式
import './assets/css/global.scss';
import './assets/css/reset.css';

// 分页组件
import Pagination from '@/components/Pagination';
Vue.component('Pagination', Pagination);

// 取数组中随机项(暂未使用)
// Array.prototype.random = function () {
//   let idx = Math.floor(Math.random() * this.length);
//   return this[idx];
// };

// 引入Viewer 
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  // 需要配置的属性 注意属性并没有引号
  title: false,
  toolbar: false
})

// 引入animation
import 'animate.css';
Vue.config.productionTip = false;
// 获取文件地址，全局配置
import webApi from './utils/webApi';
Vue.prototype.$VUE_HOST = webApi.fileUrl;

/*实时获取 sessionStorage 的值*/
Vue.prototype.resetSetItem = function (key, newVal) {
//注意isActive为要监听的属性，实际开发中请自行修改
	if (key === 'isActive') {
		// 创建一个StorageEvent事件
		var newStorageEvent = document.createEvent('StorageEvent');
		const storage = {
			setItem: function (k, val) {
				sessionStorage.setItem(k, val); 
				// 初始化创建的事件
				newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null); 
				// 派发对象
				window.dispatchEvent(newStorageEvent)
			}
		}
		return storage.setItem(key, newVal);
	}
}



new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
