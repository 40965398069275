import Vue from 'vue';
import Vuex from 'vuex';
import local from '@/utils/local';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		carouselIndex: null,
		token: ''
	},
	getters: {},
	mutations: {
		changeCarouselIndex(state, payload) {
			state.carouselIndex = payload;
		}
	},
	actions: {},
	modules: {}
});
