export default {
    getToken(key) { // 获取
        return JSON.parse(window.localStorage.getItem(key))
    },
    setToken(key, value) { // 设置
        window.localStorage.setItem(key, JSON.stringify(value))
    },
    removeToken(key) { // 移除
        window.localStorage.removeItem(key)
    },
    clearToken() { // 清空
        window.localStorage.clear()
    }
}