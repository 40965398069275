export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: "电事聚",

  /** 公司名称 */
  name: "湖北电事聚信息技术有限公司",
  /** 公司微信 */
  wechat: "xxxxxxxxx",
  /** 公司邮箱 */
  email: "xxxxxxxxx@126.com",
  /** 公司手机 */
  phone: "13477681928",
  /** 公司电话 */
  tel: "027-88915181",
  /** 公司地址 */
  address: "武汉市武昌区徐东大街128号联发国际大厦11楼",
  /** 备案号 */
  keepOnRecordIcp: "鄂ICP备2022020025号",

  /** 百度地图相关信息 */
  BDMapInfo: {
    longitude: 114.3592,
    latitude: 30.58857,
    title: "联发国际大厦",
    address: "武昌区徐东大街128号联发国际大厦11楼",
  },
};
