<template>
	<div id="app">
		<!-- 一级路由出口 -->
		<router-view></router-view>
		<!-- <go-top></go-top> -->

		<!-- <Footer class="app_footer_all"></Footer> -->
	</div>
</template>

<script>
// import GoTop from './components/GoTop.vue';
// import Footer from "@/components/Footer.vue";
export default {
	// components: { 
	// 	// Footer
	//  },
};
</script>

<style lang="scss" scoped>
#app {
	width: 100%;
	height: 1450px;
}
.app_footer_all {
	position: relative;
	bottom: 0px;
}
</style>
